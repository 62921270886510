import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useLastUpdatedProperty } from '@properties/composables/useLastUpdatedProperty';
import type { PropertiesFilters, PropertiesQuery } from '@properties/types';
import propertiesApi from '@properties/services/propertiesApi';
import { TABLE_LOADING_DELAY } from '~/constants';
import { useHelpers } from '~/composables/useHelpers';
import { DATA_TABLE_CLEAR_SELECTED_IDS, globalKey } from '~/utils/events';
import type { NavigationFailure } from '#vue-router';
import PropertyData = Domain.Properties.DataObjects.PropertyData;

export type Properties = PropertyData[];

export const usePropertiesStore = defineStore('properties', () => {
  const { updateRouteQueryParams } = useHelpers();

  const { emit: busEmit } = useEventBus(globalKey);

  const loading = ref<boolean>(false);
  const skipPriceChangeTriggeringUpdate = ref<boolean>(false);

  const records = ref<Properties[]>([]);
  const meta = ref<Record<string, string | number>>({
    count: 0,
    currentPage: 1,
    maxPrice: 0,
    minPrice: 0,
    nextPage: 0,
    perPage: 0,
    prevPage: '',
    total: 0,
  });
  const tableResults = ref<Properties>([]);
  const tableMeta = ref<Record<string, string | number>>({
    count: 0,
    currentPage: 1,
    maxPrice: 0,
    minPrice: 0,
    nextPage: 0,
    perPage: 0,
    prevPage: '',
    total: 0,
  });
  const filtersOpen = ref<boolean>(false);
  const selectedIds = ref<Set<number>>(new Set());

  const query = reactive<PropertiesQuery>({
    filter: {
      from: '0',
      to: '1000',
      search: '',
      status: '',
      boosted: '',
      featured: '',
      bedroom: '',
      bathroom: '',
    },
    page: 1,
    perPage: 30,
    sort: 'live',
  });
  const filtersSnapshot = ref<PropertiesFilters>();

  /**
   * Spin through the params in the filter
   * If the filters key is not search and a value set then include it
   * Return the count of the keys
   */
  const filtersInUse = computed<number>(
    () =>
      Object.keys(
        Object.fromEntries(
          Object.entries(query.filter).filter(([key, value]) => {
            if (['search', 'to', 'from'].includes(key)) return false;
            return value?.length > 0 || value !== '';
          }),
        ),
      ).length,
  );

  const queryParams = computed(() => {
    return {
      'filter[from]': query.filter.from,
      'filter[to]': query.filter.to,
      'filter[search]': query.filter.search,
      'filter[status]': query.filter.status,
      'filter[boosted]': query.filter.boosted,
      'filter[featured]': query.filter.featured,
      'filter[bedroom]': query.filter.bedroom,
      'filter[bathroom]': query.filter.bathroom,
      page: query.page,
      perPage: query.perPage,
      sort: query.sort,
    };
  });

  async function getProperties(
    updateTable = true,
    skipPriceChangeTrigger = false,
  ) {
    try {
      if (updateTable) {
        loading.value = true;
      }

      const { data } = await propertiesApi.get(unref(queryParams));

      if (data.value) {
        records.value = data.value.data;
        meta.value = data.value?.meta;
      }

      updateRouteQueryParams(unref(queryParams));

      if (updateTable) {
        tableResults.value = data.value?.data;
        tableMeta.value = data.value?.meta;
        skipPriceChangeTriggeringUpdate.value = skipPriceChangeTrigger;
        query.filter.from = meta.value.minPrice;
        query.filter.to = meta.value.maxPrice;
        loading.value = false;
      }
    } catch (error) {
      loading.value = false;
    }
  }

  async function updateUnitIsLive(propertyRoomId: number, payload: object) {
    try {
      const { data } = await propertiesApi.updateRoom(propertyRoomId, payload);
      return data.value;
    } catch (error) {
      loading.value = false;
    }
  }

  async function refreshTable(): Promise<void> {
    await getProperties();
  }

  function clearFilters() {
    query.filter.from = '';
    query.filter.to = '';
    query.filter.status = '';
    query.filter.boosted = '';
    query.filter.featured = '';
    query.filter.bedroom = '';
    query.filter.bathroom = '';
  }

  function updateTableResults(): void {
    loading.value = true;

    // Fake loading so the results don't update too quickly
    setTimeout(() => {
      tableResults.value = records.value;
      tableMeta.value = meta.value;
      query.page = 1;
      updateRouteQueryParams(unref(queryParams));
      busEmit(DATA_TABLE_CLEAR_SELECTED_IDS, { table: 'properties' });
      loading.value = false;
    }, TABLE_LOADING_DELAY);
  }

  function getProperty(
    id: number,
    condition?: (propertyData: PropertyData) => boolean,
  ): PropertyData | null {
    const property: unknown = tableResults.value.find(
      (propertyData: PropertyData) => {
        if (condition && !condition(propertyData)) return false;
        return propertyData.id === id;
      },
    );
    return property as PropertyData | null;
  }

  function getPropertyIndex(id: number): number {
    return tableResults.value.findIndex(
      (property: PropertyData) => property.id === id,
    );
  }

  function getAllProperties(propertyIds: number[]): Properties {
    return propertyIds
      .map((propertyId: number): PropertyData | null => getProperty(propertyId))
      .filter(
        (property: PropertyData | null) => property != null,
      ) as Properties;
  }

  function removeResult(propertyIndex: number): void {
    tableResults.value.splice(propertyIndex, 1);
  }

  function updateResult(
    propertyId: number,
    applyUpdate: (data: PropertyData) => PropertyData,
    statusFilter?: string,
  ): boolean {
    const { status } = query.filter;

    const index = getPropertyIndex(propertyId);
    if (index === -1) return false;

    const property = applyUpdate(tableResults.value[index]);
    tableResults.value[index] = property;

    // Property status filter hasn't been specified by the user
    if (!status || status === '') {
      return true;
    }

    if (!statusFilter) return true;

    if (status === statusFilter) {
      // Remove the property from the table
      removeResult(index);
      return true;
    }

    // Individual property could not be updated in the results, return false to refresh the whole table
    return false;
  }

  /**
   * Redirect the user to the properties index page, sorted by updated at (descending)
   * @param lastUpdatedId
   * @param sortField
   */
  async function openIndexPage(
    lastUpdatedId: number | null = null,
    sortField: string = '-updatedAt',
  ): Promise<void | NavigationFailure | undefined> {
    const lastUpdatedProperty = useLastUpdatedProperty();

    query.sort = sortField;

    // Highlight the last updated property in the table
    if (lastUpdatedId && sortField === '-updatedAt') {
      lastUpdatedProperty.value = {
        id: lastUpdatedId,
        seen: false,
      };
    }

    await navigateTo({
      name: 'properties-index',
      query: {
        sort: sortField,
      },
    });
  }

  // Only show the loading state when the filters modal is closed and the network is busy
  const isLoading = computed<boolean>(
    () => !filtersOpen.value && loading.value,
  );

  watch(
    [() => query.perPage, () => query.sort],
    async () => {
      await getProperties();
    },
    { deep: true },
  );

  watch(
    [() => query.page],
    async () => {
      await getProperties();
    },
    { deep: true },
  );

  watch([() => query.filter.search], () => {
    skipPriceChangeTriggeringUpdate.value = false;
    query.page = 1;
  });

  watch(
    [() => query.filter],
    () => {
      if (skipPriceChangeTriggeringUpdate.value) {
        skipPriceChangeTriggeringUpdate.value = false;
        updateRouteQueryParams(unref(queryParams));
        return;
      }

      filtersOpen.value ? getProperties(false) : getProperties(true);
    },
    { deep: true },
  );

  watch(filtersOpen, () => {
    if (filtersOpen.value) {
      // Save a snapshot
      filtersSnapshot.value = Object.assign({}, query.filter);
      return;
    }

    // Manually update the table results when the filter modal is closed, if any filters have changed
    if (
      JSON.stringify(filtersSnapshot.value) !== JSON.stringify(query.filter)
    ) {
      updateTableResults();
    }
    filtersSnapshot.value = undefined;
  });

  return {
    filtersInUse,
    filtersOpen,
    isLoading,
    meta,
    query,
    records,
    selectedIds,
    tableMeta,
    tableResults,

    clearFilters,
    getAllProperties,
    getProperties,
    getProperty,
    getPropertyIndex,
    openIndexPage,
    refreshTable,
    removeResult,
    updateResult,
    updateRouteQueryParams,
    updateUnitIsLive,
  };
});
