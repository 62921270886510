<script lang="ts" setup>
import { Input } from 'flowbite-vue';
import { useSlots, useAttrs, onMounted } from 'vue';
import { useVModel, unrefElement } from '@vueuse/core';
import type { InputType, InputSize } from './types';

defineOptions({
  inheritAttrs: false
});

const slots = useSlots();
const attrs = useAttrs();

interface UhInputProps {
  id?: string;
  name: string;
  label?: string;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  modelValue?: string | number;
  size?: InputSize;
  fontSize?: string;
  dataTestid?: string;
  sideLabelClass?: string;
  labelFontWeightClass?: string
}

const props = withDefaults(defineProps<UhInputProps>(), {
  id: '',
  type: 'text',
  disabled: false,
  required: false,
  placeholder: '',
  invalid: false,
  errorMessage: '',
  label: '',
  size: 'md',
  modelValue: '',
  fontSize: '!text-base',
  dataTestid: '',
  sideLabelClass: '',
  labelFontWeightClass: 'font-medium'
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const wrapperClasses = computed(() => attrs.class);

const inputWrapper = ref<HTMLElement | null>(null);

onMounted(() => {
  const el = unrefElement(inputWrapper) as HTMLElement;
  el.removeAttribute('name');
});
</script>

<template>
  <div :class="wrapperClasses">
    <label v-if="props.label" :for="props.id" class="mb-2 block text-sm text-gray-900" :class="[props.labelFontWeightClass]">
      <span>{{ props.label }}</span>
      <span v-if="props.required" class="ml-1 text-red-500">*</span>
      <span v-if="slots.sideLabel" class="mt text-sm text-gray-900" :class="props.sideLabelClass">
        <slot name="sideLabel"></slot>
      </span>
    </label>

    <Input
      :id="props.id"
      ref="inputWrapper"
      v-model="model"
      :class="[{ 'border-red-600': props.invalid }, props.fontSize]"
      :data-testid="props.dataTestid"
      :disabled="props.disabled"
      :name="props.name"
      :placeholder="props.placeholder"
      :required="props.required"
      :size="props.size"
      :type="props.type"
      class="!focus:border-blue-300 !focus:ring-4 min-h-[42px] border-gray-200 bg-white py-0 hover:border-gray-900 active:border-gray-900 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:border-gray-300"
      v-bind="attrs"
    >
      <template v-if="slots.prefix" #prefix>
        <slot name="prefix"></slot>
      </template>

      <template v-if="slots.suffix" #suffix>
        <slot name="suffix"></slot>
      </template>

      <template
        v-if="slots.helper || (props.invalid && props.errorMessage !== '')"
        #helper
      >
      <span
        v-if="props.invalid"
        class="mt-2 text-red-600"
        :data-testid="`error-${props.dataTestid}`"
      >
        {{ props.errorMessage }}
      </span>
        <slot name="helper"></slot>
      </template>
    </Input>
  </div>
</template>
